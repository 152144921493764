require("bootstrap");
require('datatables.net');
window.toastr = require('toastr');
window.$ = window.jQuery = require('jquery');

toastr.options =
{
  "positionClass": "custom-tostr-full-width",  
    "closeButton" : true,
    "progressBar" : true,
    "target":'.message-container',
}